import React, { createRef } from "react";
import TopBar from "../features/site/TopBar";
import { Card, Flex, Heading, Text, Theme } from "@radix-ui/themes";
import { Link } from "../components/Link";
import { useBackgroundMachineSlideshow } from "../hooks/useBackgroundMachineSlideshow";

/**
 * This page is displayed when a user tries to access a page they
 * are not authorized to view.
 *
 * This page does not rely on any router or authentication context, and is
 * fully self-contained with theme included.
 */
export default function Unauthorized() {
  const ref = createRef<HTMLDivElement>();
  useBackgroundMachineSlideshow(ref);

  return (
    <>
      <style>
        {`
          html, body {
            margin: 0
          }
        `}
      </style>
      <Theme appearance="light">
        <TopBar />
        <Flex
          ref={ref}
          direction="column"
          align="center"
          justify="center"
          style={{
            backgroundImage: "url(https://smart.evueanywhere.com/images/background/pt-vario.webp)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            transition: "background-image 0.6s ease-in-out",
            height: "100vh",
            width: "100vw",
          }}
        >
          <Card size="4" style={{ width: 400 }}>
            <Heading as="h3" size="6" trim="start" mb="5">
              Unauthorized
            </Heading>
            <Text as="div" size="2" weight="medium" mb="3">
              You are not authorized to view this page.
            </Text>

            <Text as="div" size="2" weight="medium" mb="3">
              Please <Link to="/login">login</Link>, or if you believe this is in error, contact Larry Erickson.
            </Text>
          </Card>
        </Flex>
      </Theme>
    </>
  );
}
