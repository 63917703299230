import BaseTheme from "../theme/BaseTheme";
import TopBar from "../features/site/TopBar";
import { Outlet } from "react-router-dom";
import { Container } from "@radix-ui/themes";

export default function FullPageLayout() {
  return (
    <BaseTheme>
      <TopBar />
      <Container>
        <Outlet />
      </Container>
    </BaseTheme>
  );
}
