import BaseTheme from "../theme/BaseTheme";
import { Outlet } from "react-router-dom";
import React from "react";

export default function AuthLayout() {
  return (
    <>
      <style>
        {`
          html, body {
            margin: 0
          }
        `}
      </style>
      <BaseTheme appearance="light">
        <Outlet />
      </BaseTheme>
    </>
  );
}
