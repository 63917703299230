import authSlice from "../slices/authSlice";
import mapSlice from "../slices/mapSlice";
import siteSlice from "../slices/siteSlice";
import { cdsApi } from "../slices/cdsApi";
import { cloudApi } from "../slices/cloudApi";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    map: mapSlice,
    site: siteSlice,
    [cdsApi.reducerPath]: cdsApi.reducer,
    [cloudApi.reducerPath]: cloudApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      cdsApi.middleware, //
      cloudApi.middleware,
    ]),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
