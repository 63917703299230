import { RootState } from "../store/store";
import { createSlice } from "@reduxjs/toolkit";

interface SiteState {
  infoDrawerExpanded: boolean;
  navDrawerExpanded: boolean;
}

const initialState = {
  infoDrawerExpanded: false,
} as SiteState;

const siteSlice = createSlice({
  name: "site",
  initialState,
  reducers: {
    openInfoDrawer: (state) => {
      state.infoDrawerExpanded = true;
    },
    closeInfoDrawer: (state) => {
      state.infoDrawerExpanded = false;
    },
  },
});

export const {
  openInfoDrawer, //
  closeInfoDrawer,
} = siteSlice.actions;

export const selectInfoDrawerExpanded = (state: RootState) => state.site.infoDrawerExpanded;

export default siteSlice.reducer;
