import { JobNumber, LogisticsSystemStatus } from "@etechinc/smartview-core";
import { cloudApi } from "./cloudApi";

export const statusApi = cloudApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllStatuses: builder.query<(JobNumber & LogisticsSystemStatus)[], void>({
      query: () => `/status`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetAllStatusesQuery } = statusApi;
