import React, { ReactNode } from "react";
import LoadingView from "../views/LoadingView";
import { useAuth } from "../hooks/useAuth";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetUserQuery } from "../slices/userApi";
import { useGetUserSettingsQuery } from "../slices/userSettingApi";

interface Props {
  children: ReactNode;
}

/**
 * Preload user and user settings.
 * Use RTK query `isLoading` to track initial load of the API call.
 */
export default function RequireUserSettings({ children }: Props) {
  const auth = useAuth();
  const { isLoading: isUserLoading } = useGetUserQuery(auth?.username ?? skipToken);
  const { isLoading: isUserSettingsLoading } = useGetUserSettingsQuery({ username: auth?.username });

  if (isUserLoading || isUserSettingsLoading) {
    return <LoadingView />;
  }

  return children;
}
