import React, { lazy } from "react";
import Loadable from "../components/Loadable";
import MapLanding from "../views/MapLanding";
import MapLayout from "../layout/MapLayout";
import RequireAuth from "../components/RequireAuth";
import RequireFacilitySettings from "../components/RequireFacilitySettings";
import RequireJobAccess from "../components/RequireJobAccess";
import RequireUserSettings from "../components/RequireUserSettings";

const MapView = Loadable(lazy(() => import("../views/MapView")));

export const MapRoutes = {
  path: "/",
  element: (
    <RequireAuth>
      <RequireUserSettings>
        <MapLayout />
      </RequireUserSettings>
    </RequireAuth>
  ),
  children: [
    {
      path: "/map",
      element: <MapLanding />,
    },
    {
      path: "/map/:jobNumber/*",
      element: (
        <RequireJobAccess>
          <RequireFacilitySettings>
            <MapView />
          </RequireFacilitySettings>
        </RequireJobAccess>
      ),
    },
  ],
};
