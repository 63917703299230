import { Avatar, DropdownMenu, IconButton } from "@radix-ui/themes";
import { Link, useNavigate } from "react-router-dom";
import { acronymFrom } from "@etechinc/smartview-core";
import { skipToken } from "@reduxjs/toolkit/query";
import { useAuth } from "../../hooks/useAuth";
import { useGetUserQuery } from "../../slices/userApi";

export default function UserMenu() {
  const auth = useAuth();
  const navigate = useNavigate();
  const { data: user } = useGetUserQuery(auth.username ?? skipToken);

  const handleLogout = () => {
    auth.logout(() => navigate("/login"));
  };

  return (
    <>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <IconButton radius="full" color="gray" size="4" style={{ boxShadow: "var(--shadow-6)" }}>
            <Avatar fallback={acronymFrom(`${user?.firstName} ${user?.lastName}`)} />
          </IconButton>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content>
          <DropdownMenu.Item asChild>
            <Link to={`/user/settings`}>Settings</Link>
          </DropdownMenu.Item>
          <DropdownMenu.Separator />
          <DropdownMenu.Item onClick={handleLogout}>Logout</DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </>
  );
}
