import { FacilitySetting, JobParams } from "@etechinc/smartview-core";
import { cloudApi } from "./cloudApi";

export const facilitySettingApi = cloudApi.injectEndpoints({
  endpoints: (builder) => ({
    getFacilitySettings: builder.query<FacilitySetting[], JobParams>({
      query: ({ jobNumber }) => `/facility-setting/${jobNumber}`,
      providesTags: ["FacilitySetting"],
    }),
    setFacilitySetting: builder.mutation({
      query: (payload: FacilitySetting) => ({
        url: `/facility-setting/${payload.jobNumber}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["FacilitySetting"],
    }),
  }),
});

export const {
  useGetFacilitySettingsQuery, //
  useSetFacilitySettingMutation,
} = facilitySettingApi;
