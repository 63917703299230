import FullPageLayout from "../layout/FullPageLayout";
import RequireAuth from "../components/RequireAuth";
import RequireRole from "../components/RequireRole";
import StatusView from "../views/StatusView";
import { Role } from "../constants/Role";

export const StatusRoutes = {
  path: "/",
  element: (
    <RequireAuth>
      <RequireRole roles={[Role.ADMIN, Role.DEVELOPER, Role.OPERATIONS, Role.PROGRAMMER, Role.TECHNICIAN]}>
        <FullPageLayout />
      </RequireRole>
    </RequireAuth>
  ),
  children: [
    {
      path: "/status",
      element: <StatusView />,
    },
  ],
};
