import LoadingView from "../views/LoadingView";
import { AuthLoginRequest } from "../interfaces/AuthLoginRequest";
import { AuthRegisterRequest } from "../interfaces/AuthRegisterRequest";
import { clearAuth, setAuth } from "../slices/authSlice";
import { createContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLoginMutation, useRegisterMutation, useVerifyMutation } from "../slices/authApi";

interface AuthContextType {
  username?: string;
  token?: string;
  error?: string;
  initialized: boolean;
  login: (values: AuthLoginRequest, callback: VoidFunction) => void;
  logout: (callback: VoidFunction) => void;
  register: (values: AuthRegisterRequest, callback: VoidFunction) => void;
}

export const AuthContext = createContext<AuthContextType>(null!);

export default function AuthProvider({ children }: { children: React.ReactNode }) {
  const [loginApi] = useLoginMutation();
  const [registerApi] = useRegisterMutation();
  const [verifyApi] = useVerifyMutation();
  const [error, setError] = useState<string>();
  const [username, setUsername] = useState<string>();
  const [token, setToken] = useState<string>();
  const [initialized, setInitialized] = useState<boolean>(false);
  const dispatch = useDispatch();

  const clear = () => {
    setUsername(undefined);
    setToken(undefined);
    setError(undefined);
    localStorage.removeItem("username");
    localStorage.removeItem("token");
    dispatch(clearAuth());
  };

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    if (!initialized) {
      const username = localStorage.getItem("username");
      const token = localStorage.getItem("token");
      if (username && token) {
        setUsername(username);
        setToken(token);
        dispatch(setAuth({ username: username, token: token }));
        await verify(username);
      }
      setInitialized(true);
    }
  };

  const login = async (values: AuthLoginRequest, callback: VoidFunction) => {
    await loginApi(values)
      .unwrap()
      .then((data) => {
        const { username, token } = data;
        setUsername(username);
        setToken(token);
        setError(undefined);
        localStorage.setItem("username", username);
        localStorage.setItem("token", token);
        callback();
      })
      .catch((error) => {
        setError(error.data);
      });
  };

  const logout = (callback: VoidFunction) => {
    clear();
    callback();
  };

  const register = async (values: AuthRegisterRequest, callback: VoidFunction) => {
    await registerApi(values)
      .unwrap()
      .then((data) => {
        const { username, token } = data;
        setUsername(username);
        setToken(token);
        setError(undefined);
        localStorage.setItem("username", username);
        localStorage.setItem("token", token);
        callback();
      })
      .catch((error) => {
        setError(error.data);
      });
  };

  const verify = async (username: string) => {
    await verifyApi({ username })
      .unwrap()
      .then(() => {})
      .catch((error) => {
        setError(error.data);
        clear();
      });
  };

  let value = { username, token, error, initialized, login, logout, register };

  return <>{initialized ? <AuthContext.Provider value={value}>{children}</AuthContext.Provider> : <LoadingView />}</>;
}
