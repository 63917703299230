import { ChartDanger, ChartSuccess, ChartWarning } from "../../constants/ChartPalette";
import { LogisticsSystemStatus } from "@etechinc/smartview-core";
import { Status } from "../../components/Status";
import { timeFromNow } from "../../utils/timeUtils";
import { useEffect, useState } from "react";

interface Props {
  status?: LogisticsSystemStatus;
}

export const LogisticsSystemStatusIndicator = ({ status }: Props) => {
  const [color, setColor] = useState(ChartSuccess);

  useEffect(() => {
    const age = timeFromNow(status?.timestamp);

    switch (true) {
      case !status:
      case status?.ok !== true:
        setColor(ChartDanger);
        break;
      case age < 2 && status?.ok === true:
        setColor(ChartSuccess);
        break;
      case age < 60 && status?.ok === true:
        setColor(ChartWarning);
        break;
      default:
        setColor(ChartDanger);
        break;
    }
  }, [status]);

  return (
    <>
      <Status color={color} />
    </>
  );
};
