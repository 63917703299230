import React, { ReactNode, useEffect, useState } from "react";
import Unauthorized from "../views/Unauthorized";
import LoadingView from "../views/LoadingView";
import { useGetFacilitiesQuery } from "../slices/facilityApi";
import { useParams } from "react-router-dom";
import { useUserCurrentIsKannegiesserEmployee } from "../hooks/useUserCurrentIsKannegiesserEmployee";

interface Props {
  children: ReactNode;
}

/**
 * Require the user to have access to the job number in the URL.
 */
export default function RequireJobAccess({ children }: Props) {
  const { jobNumber } = useParams();
  const { data: facilities = [], isLoading } = useGetFacilitiesQuery();
  const [hasJobAccess, setJobAccess] = useState<boolean>(false);
  const isAdmin = useUserCurrentIsKannegiesserEmployee();

  useEffect(() => {
    setJobAccess(isAdmin || facilities?.some((f) => f.jobNumber === jobNumber));
  }, [facilities, isAdmin]);

  // Display loading view while loading facilities
  if (isLoading) {
    return <LoadingView />;
  }

  // Display unauthorized view if the user doesn't have access to the job number
  if (!hasJobAccess) {
    return <Unauthorized />;
  }

  return children;
}
