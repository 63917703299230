import { AuthLoginRequest } from "../interfaces/AuthLoginRequest";
import { AuthRegisterRequest } from "../interfaces/AuthRegisterRequest";
import { AuthResponse } from "../interfaces/AuthResponse";
import { AuthVerifyRequest } from "../interfaces/AuthVerifyRequest";
import { cloudApi } from "./cloudApi";

export const authApi = cloudApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<AuthResponse, AuthLoginRequest>({
      query: (payload) => ({
        url: `/auth/login`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
    register: builder.mutation<AuthResponse, AuthRegisterRequest>({
      query: (payload) => ({
        url: `/auth/register`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
    verify: builder.mutation<AuthResponse, AuthVerifyRequest>({
      query: (payload) => ({
        url: `/auth/verify`,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginMutation, //
  useRegisterMutation,
  useVerifyMutation,
} = authApi;
