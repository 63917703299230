import { Link as LinkText } from "@radix-ui/themes";
import { Link as RouterLink, LinkProps as BaseLinkProps } from "react-router-dom";

/**
 * Link component is a wrapper around the react-router-dom Link component,
 * applying Radix UI Link component styles.
 */
export function Link(props: BaseLinkProps) {
  return (
    <LinkText asChild>
      <RouterLink {...props} />
    </LinkText>
  );
}
