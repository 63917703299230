import { Box, Flex } from "@radix-ui/themes";
import UserMenu from "../users/UserMenu";

export default function TopBar() {
  return (
    <>
      <Flex
        justify="end"
        position="fixed"
        top="0"
        left="0"
        right="0"
        m="3"
        style={{
          pointerEvents: "none",
        }}
      >
        <Box style={{ pointerEvents: "all" }}>
          <UserMenu />
        </Box>
      </Flex>
    </>
  );
}
