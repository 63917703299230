import { User } from "../types/User";
import { cloudApi } from "./cloudApi";

export const userApi = cloudApi.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<User, string>({
      query: (username) => `/user/${username}`,
      providesTags: ["User"],
    }),
    setUserPassword: builder.mutation({
      query: (payload: User) => ({
        url: `/user/${payload.username}/password`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["User"],
    }),
    setUser: builder.mutation({
      query: (payload: User) => ({
        url: `/user/${payload.username}/profile`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["User"],
    }),
  }),
});

export const {
  useGetUserQuery, //
  useSetUserMutation,
  useSetUserPasswordMutation,
} = userApi;
