import { UserSettingKey } from "../constants/UserSettingKey";
import { useGetUserSettingsQuery } from "../slices/userSettingApi";
import { useUserCurrent } from "./useUserCurrent";

/**
 * Get a user setting for the current user by `key`.
 */
export const useUserSetting = (key?: UserSettingKey): string | undefined => {
  const user = useUserCurrent();

  const { userSetting } = useGetUserSettingsQuery(
    { username: user?.username },
    {
      selectFromResult: ({ data: userSettings }) => ({
        userSetting: userSettings?.find((userSetting) => userSetting.key === key),
      }),
      skip: !user?.username || !key,
    },
  );

  return userSetting?.value;
};
