import React from "react";
import { LogisticsSystemStatusTable } from "../features/status/LogisticsSystemStatusTable";
import { Heading } from "@radix-ui/themes";

export default function StatusView() {
  return (
    <>
      <Heading as="h1" size="6" mb="5">
        Status
      </Heading>

      <LogisticsSystemStatusTable />
    </>
  );
}
