import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const cdsApi = createApi({
  reducerPath: "cds",
  baseQuery: fetchBaseQuery({ baseUrl: import.meta.env.VITE_API_URI }),
  endpoints: (builder) => ({
    syncAllJobs: builder.query<void, void>({
      query: () => `/cds/job`,
    }),
    syncJob: builder.query<void, string>({
      query: (jobNumber) => `/cds/job/${jobNumber}`,
    }),
  }),
});

export const { useSyncAllJobsQuery, useSyncJobQuery } = cdsApi;
