import { RefObject, useEffect } from "react";

export const useBackgroundMachineSlideshow = (ref: RefObject<HTMLDivElement>) => {
  const images = [
    "blanketmaster.webp",
    "cfm.webp",
    "csm.webp",
    "favorit-vario.webp",
    "fax-1200.webp",
    "fax-m.webp",
    "futura.webp",
    "hpm.webp",
    "power-press.webp",
    "power-spin.webp",
    "powerdry.webp",
    "pt-vario.webp",
    "shm.webp",
    "fax-m.webp",
  ];

  const loadImage = async (path: string) => {
    const response = await fetch(`${import.meta.env.VITE_ROOT_URI}/images/background/${path}`);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    ref.current?.style.setProperty("background-image", `url(${url})`);
  };

  useEffect(() => {
    const id = setInterval(() => {
      const index = Math.floor(Math.random() * images.length);
      const image = images[index];
      loadImage(image);
    }, 3000);
    return () => clearInterval(id);
  }, []);
};
