import { AuthResponse } from "../interfaces/AuthResponse";
import { RootState } from "../store/store";
import { authApi } from "./authApi";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type AuthState = {
  username?: string;
  token?: string;
};

const slice = createSlice({
  name: "auth",
  initialState: { user: undefined, token: undefined } as AuthState,
  reducers: {
    clearAuth: (state) => {
      state.username = undefined;
      state.token = undefined;
    },
    setAuth: (state, { payload }: PayloadAction<AuthResponse>) => {
      state.username = payload.username;
      state.token = payload.token;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }: PayloadAction<AuthResponse>) => {
      state.username = payload.username;
      state.token = payload.token;
    });
  },
});

export const {
  clearAuth, //
  setAuth,
} = slice.actions;

export const selectAuthUsername = (state: RootState) => state.auth.username;
export const selectAuthToken = (state: RootState) => state.auth.token;

export default slice.reducer;
