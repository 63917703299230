/**
 * Polling interval for RTK Queries, defined per environment.
 */
export const polling = {
  pollingInterval: import.meta.env.VITE_POLLING_INTERVAL,
};

/**
 * Polling interval, in seconds.
 */
export const poll = (interval: number) => ({
  pollingInterval: interval * 1000,
});
