import { poll } from "../utils/reduxUtils";
import { sortFacilitiesByName } from "../utils/facilityUtils";
import { useGetFacilitiesQuery } from "../slices/facilityApi";
import { useMemo } from "react";

/**
 * Hook to get all facilities.
 * API returns only facilities user has access to, based upon their organization configuration.
 */
export const useFacilities = () => {
  const { data: facilities } = useGetFacilitiesQuery(undefined, poll(900));

  return useMemo(() => {
    return sortFacilitiesByName(facilities);
  }, [facilities]);
};
