/**
 * Key values for user settings
 */
export enum UserSettingKey {
  JOB = "job:number",
  MAP_LAYER_MODE = "map:layer-mode",
  MAP_LAYER_TYPE = "map:layer-type",
  MAP_RAIL_LABEL_DISPLAY_TYPE = "map:rail-label-display-type",
  WEIGHT_UNIT = "unit:weight",
}
