import AuthProvider from "./contexts/AuthContext";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { store } from "./store/store";
import { useAppRoutes } from "./routes/useAppRoutes";

function App() {
  const routes = useAppRoutes();

  return (
    <>
      <Provider store={store}>
        <SnackbarProvider>
          <AuthProvider>
            <RouterProvider router={routes} />
          </AuthProvider>
        </SnackbarProvider>
      </Provider>
    </>
  );
}

export default App;
