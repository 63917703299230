import { AuthRoutes } from "./AuthRoutes";
import { MapRoutes } from "./MapRoutes";
import { StatusRoutes } from "./StatusRoutes";
import { UserRoutes } from "./UserRoutes";
import { createBrowserRouter, Navigate } from "react-router-dom";

/**
 * URL Parameters:
 *   :slug            Organization
 *   :jobNumber       Job or Facility
 *   :areaId          Area
 *   :producerViewId  Producer View
 *   :producerID      Producer
 */
export const useAppRoutes = () => {
  return createBrowserRouter([
    {
      path: "/",
      element: <Navigate to={"/map"} />,
    },
    AuthRoutes,
    MapRoutes,
    StatusRoutes,
    UserRoutes,
  ]);
};
