import React, { ReactNode } from "react";
import Unauthorized from "../views/Unauthorized";
import { Role } from "../constants/Role";
import { useUserRolesIncludes } from "../hooks/useUserRolesIncludes";

interface Props {
  children: ReactNode;
  roles: Role[];
}

/**
 * Require a user to have one of the provided roles.
 */
export default function RequireRole({ children, roles }: Props) {
  const hasRoles = useUserRolesIncludes(roles);

  // Not authorized, show unauthorized page
  if (!hasRoles) {
    return <Unauthorized />;
  }

  return children;
}
