import dayjs from "dayjs";
import { Button, Dialog, Flex, IconButton, Text } from "@radix-ui/themes";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { LogisticsSystemStatus } from "@etechinc/smartview-core";
import { asTimeAgo } from "../../utils/timeUtils";

interface Props {
  status?: LogisticsSystemStatus;
}

export const LogisticsSystemStatusErrorDialog = ({ status }: Props) => {
  return (
    <>
      <Dialog.Root>
        <Dialog.Trigger>
          <IconButton variant="ghost">
            <InfoCircledIcon width="18" height="18" />
          </IconButton>
        </Dialog.Trigger>

        <Dialog.Content style={{ maxWidth: 450 }}>
          <Dialog.Title>Error</Dialog.Title>
          <Dialog.Description size="2" mb="4">
            {status?.message}
            {status?.status && (
              <>
                <Text as="div" size="2" color="gray">
                  Code: {status?.status ?? "--"} {status?.statusText}
                </Text>
              </>
            )}
            {status?.timestamp && (
              <>
                <Text as="div" size="2" color="gray">
                  {dayjs(status?.timestamp).format("MMMM D, YYYY [at] h:mm a")} ({asTimeAgo(status?.timestamp)})
                </Text>
              </>
            )}
          </Dialog.Description>

          <Flex gap="3" mt="4" justify="end">
            <Dialog.Close>
              <Button>Ok</Button>
            </Dialog.Close>
          </Flex>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};
