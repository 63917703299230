/**
 * Roles.
 * Internal roles for Kannegiesser / ETECH:
 * - ADMIN
 * - DEVELOPER
 * - OPERATIONS
 * - PROGRAMMER
 * - TECHNICIAN
 *
 * External roles for end-users:
 * - GUEST
 * - EXECUTIVE
 * - MAINTENANCE
 * - MANAGEMENT
 * - MECHANIC
 * - OPERATOR
 * - PRODUCTION
 * - SUPERVISOR
 */
export enum Role {
  ADMIN = "ADMIN",
  DEVELOPER = "DEVELOPER",
  EXECUTIVE = "EXECUTIVE",
  GUEST = "GUEST",
  MAINTENANCE = "MAINTENANCE",
  MECHANIC = "MECHANIC",
  MANAGEMENT = "MANAGEMENT",
  OPERATIONS = "OPERATIONS",
  OPERATOR = "OPERATOR",
  PRODUCTION = "PRODUCTION",
  PROGRAMMER = "PROGRAMMER",
  SUPERVISOR = "SUPERVISOR",
  TECHNICIAN = "TECHNICIAN",
}
