import { UserSettingKey } from "../constants/UserSettingKey";
import { linkToJob } from "../utils/linkTo";
import { useEffect } from "react";
import { useFacilities } from "../hooks/useFacilities";
import { useNavigate } from "react-router-dom";
import { useUserSetting } from "../hooks/useUserSetting";

/**
 * Redirect to the last facility user visited, or first facility user has access to.
 */
export default function MapLanding() {
  const facilities = useFacilities();
  const navigate = useNavigate();
  const lastJobNumber = useUserSetting(UserSettingKey.JOB);

  // Redirect to first facility user has access to or display info page
  useEffect(() => {
    if (lastJobNumber) navigate(linkToJob(lastJobNumber));
    else {
      if (facilities && facilities.length > 0) {
        const facility = facilities[0];
        navigate(linkToJob(facility.jobNumber));
      }
    }
  }, [facilities, lastJobNumber]);

  return <></>;
}
