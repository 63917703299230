import React, { lazy } from "react";
import AuthLayout from "../layout/AuthLayout";
import Loadable from "../components/Loadable";

const LoginPage = Loadable(lazy(() => import("../views/LoginPage")));
const RegisterPage = Loadable(lazy(() => import("../views/RegisterPage")));

/**
 * Login and register routes - no authentication required
 */
export const AuthRoutes = {
  path: "/",
  element: <AuthLayout />,
  children: [
    {
      path: "/login",
      element: <LoginPage />,
    },
    {
      path: "/register",
      element: <RegisterPage />,
    },
  ],
};
