import { RootState } from "../store/store";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const cloudApi = createApi({
  reducerPath: "cloud",
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API_URI,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token;
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Facility", "FacilitySetting", "Organization", "Tag", "User", "UserSetting"],
  endpoints: () => ({}),
});
