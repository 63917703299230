import React, { lazy } from "react";
import FullPageLayout from "../layout/FullPageLayout";
import Loadable from "../components/Loadable";
import RequireAuth from "../components/RequireAuth";
import RequireUserSettings from "../components/RequireUserSettings";

const UserPage = Loadable(lazy(() => import("../views/UserPage")));

/**
 * User routes are protected by authentication, and do not require
 * role-based authorization as a user can only access their own settings.
 */
export const UserRoutes = {
  path: "/",
  element: (
    <RequireAuth>
      <RequireUserSettings>
        <FullPageLayout />
      </RequireUserSettings>
    </RequireAuth>
  ),
  children: [
    {
      path: "/user/settings",
      element: <UserPage />,
    },
  ],
};
