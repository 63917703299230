import { Facility } from "@etechinc/smartview-core";
import { cloudApi } from "./cloudApi";

export const facilityApi = cloudApi.injectEndpoints({
  endpoints: (builder) => ({
    getFacilities: builder.query<Facility[], void>({
      query: () => `/facility`,
      providesTags: ["Facility"],
      keepUnusedDataFor: 3600,
    }),
    setFacility: builder.mutation({
      query: (payload: Facility) => ({
        url: `/facility/${payload.jobNumber}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Facility"],
    }),
  }),
});

export const {
  useGetFacilitiesQuery, //
  useSetFacilityMutation,
} = facilityApi;
