import { LogisticsSystemStatus } from "@etechinc/smartview-core";
import { polling } from "../utils/reduxUtils";
import { useGetAllStatusesQuery } from "../slices/statusApi";
import { useMemo } from "react";

/**
 * Hook to get all logistics system statuses grouped by job number
 */
export const useLogisticsSystemStatuses = (): Record<string, LogisticsSystemStatus[]> | undefined => {
  const { data: statuses } = useGetAllStatusesQuery(undefined, polling);

  return useMemo(() => {
    return statuses?.reduce(
      (acc, status) => {
        const { jobNumber } = status;
        if (!jobNumber) return acc;

        if (!acc[jobNumber]) {
          acc[jobNumber] = [];
        }
        acc[jobNumber].push(status);
        return acc;
      },
      {} as Record<string, LogisticsSystemStatus[]>,
    );
  }, [statuses]);
};
