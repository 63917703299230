import { UserParams, UserSetting } from "@etechinc/smartview-core";
import { cloudApi } from "./cloudApi";

export const userSettingApi = cloudApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserSettings: builder.query<UserSetting[], UserParams>({
      query: ({ username }) => `/user-setting/${username}`,
      providesTags: ["UserSetting"],
    }),
    setUserSetting: builder.mutation({
      query: (payload: UserSetting) => ({
        url: `/user-setting/${payload.username}`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["UserSetting"],
    }),
  }),
});

export const {
  useGetUserSettingsQuery, //
  useSetUserSettingMutation,
} = userSettingApi;
