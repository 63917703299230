import { skipToken } from "@reduxjs/toolkit/query";
import { useAuth } from "./useAuth";
import { useGetUserQuery } from "../slices/userApi";

/**
 * Get the current authenticated user.
 */
export const useUserCurrent = () => {
  const auth = useAuth();
  const { data: user } = useGetUserQuery(auth.username ?? skipToken);

  return user;
};
