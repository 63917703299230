export const ChartInfo = "#008ffb"; //"#5470c6"; #03a9f4
export const ChartSuccess = "#91cc75"; // #4caf50
export const ChartWarning = "#fac858"; // #ff9800
export const ChartDanger = "#ee6666"; // #ef5350
export const ChartOrange = "#fc8452";
export const ChartTeal = "#32c5e9";
export const ChartPink = "#ea7ccc";
export const ChartDisabled = "#313131";
export const ChartDisabledLight = "#dddddd";

export const DefaultSeriesPalette = [
  "#5470c6",
  "#91cc75",
  "#fac858",
  "#ee6666",
  "#73c0de",
  "#3ba272",
  "#fc8452",
  "#9a60b4",
  "#ea7ccc",
];

export const RadialSeriesPalette = [
  "#37a2da",
  "#32c5e9",
  "#67e0e3",
  "#9fe6b8",
  "#ffdb5c",
  "#ff9f7f",
  "#fb7293",
  "#e062ae",
  "#e690d1",
  "#e7bcf3",
  "#9d96f5",
  "#8378ea",
  "#96bfff",
];

export enum MetroColorPalette {
  AMBER = "#f0a30a",
  BROWN = "#825a2c",
  COBALT = "#0050ef",
  CRIMSON = "#a20025",
  CYAN = "#1ba1e2",
  EMERALD = "#008a00",
  GREEN = "#60a917",
  INDIGO = "#6a00ff",
  LIME = "#a4c400",
  MAGENTA = "#d80073",
  MAUVE = "#76608a",
  OLIVE = "#6d8764",
  ORANGE = "#fa6800",
  PINK = "#f472d0",
  RED = "#e51400",
  SIENNA = "#a0522d",
  STEEL = "#647687",
  TEAL = "#00aba9",
  VIOLET = "#aa00ff",
  YELLOW = "#e3c800",
}
