import { Facility } from "@etechinc/smartview-core";

//------------------------------
//  Equality comparer
//------------------------------

export const facilityNameComparer = (a: Facility, b: Facility) => {
  if (!a.name) return 1;
  if (!b.name) return -1;

  return a.name?.localeCompare(b.name);
};

//------------------------------
//  Sorting
//------------------------------

export const sortFacilitiesByName = (facilities: Facility[] = []): Facility[] => {
  return [...facilities].sort(facilityNameComparer);
};
