import React, { ReactNode } from "react";
import LoadingView from "../views/LoadingView";
import { useGetFacilitySettingsQuery } from "../slices/facilitySettingApi";
import { useParams } from "react-router-dom";

interface Props {
  children: ReactNode;
}

/**
 * Preload facility settings
 * Use RTK query `isLoading` to track initial load of the API call.
 */
export default function RequireFacilitySettings({ children }: Props) {
  const { jobNumber } = useParams();
  const { isLoading: isLoading } = useGetFacilitySettingsQuery({ jobNumber }, { skip: !jobNumber });

  if (isLoading) {
    return <LoadingView />;
  }

  return children;
}
