import styled from "@emotion/styled";

export const Status = styled.div<{ color?: string }>`
  display: flex;
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  transition: background-color 180ms linear;
`;
