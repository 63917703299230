import { LogisticsSystemStatus } from "@etechinc/smartview-core";
import { timeFromNow } from "../utils/timeUtils";

/**
 * Returns true if the logistics system is in error
 */
export const useLogisticsSystemStatusErrorState = (status?: LogisticsSystemStatus): boolean => {
  const age = timeFromNow(status?.timestamp);

  switch (true) {
    case !status:
    case status?.ok !== true:
      return true;
    case age < 2 && status?.ok === true:
      return false;
    case age < 60 && status?.ok === true:
    default:
      return true;
  }
};
