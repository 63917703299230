import { ReactNode } from "react";
import { Theme } from "@radix-ui/themes";
import "@radix-ui/themes/styles.css";
import "react-material-symbols/sharp";

interface Props {
  appearance?: "light" | "dark";
  children: ReactNode;
}

export default function BaseTheme({ appearance = "dark", children }: Props) {
  return (
    <>
      <Theme appearance={appearance} accentColor="blue" panelBackground="translucent">
        {children}
      </Theme>
    </>
  );
}
