import React from "react";
import { Flex, Table } from "@radix-ui/themes";
import { LogisticsSystemStatus } from "@etechinc/smartview-core";
import { LogisticsSystemStatusErrorDialog } from "./LogisticsSystemStatusErrorDialog";
import { LogisticsSystemStatusIndicator } from "./LogisticsSystemStatusIndicator";
import { useLogisticsSystemStatusErrorState } from "../../hooks/useLogisticsSystemStatusErrorState";

interface Props {
  status?: LogisticsSystemStatus;
}

export const LogisticsSystemStatusTableCell = ({ status }: Props) => {
  const error = useLogisticsSystemStatusErrorState(status);

  return (
    <>
      <Table.Cell>
        <Flex align="center" gap="1">
          <LogisticsSystemStatusIndicator status={status} />
          {status?.version ?? "--"}
          {error && <LogisticsSystemStatusErrorDialog status={status} />}
        </Flex>
      </Table.Cell>
    </>
  );
};
