import { Role } from "../constants/Role";
import { useUserCurrent } from "./useUserCurrent";

/**
 * Check if the current user has any of the provided roles.
 */
export const useUserRolesIncludes = (roles: Role[]) => {
  const user = useUserCurrent();

  return user?.roles?.some((role) => roles.includes(role));
};
