import React, { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

interface Props {
  children: ReactNode;
}

/**
 * Require the user to be authenticated.
 */
export default function RequireAuth({ children }: Props) {
  const location = useLocation();
  const auth = useAuth();

  // Not authenticated, redirect to login
  if (!auth.username) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
